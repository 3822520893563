<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-3">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex space-x-2">
            <div class="sm:w-auto flex">
              <div class="flex items-center">
                <label class="mr-1 text-xs whitespace-no-wrap">Period</label>
                <vx-input-group class="w-52">
                  <template slot="prepend">
                    <vx-input-group>
                      <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.fromDate"></flat-pickr>
                    </vx-input-group>
                    <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                      <span class="p-0">s/d</span>
                    </div>
                    <vx-input-group>
                      <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.toDate"></flat-pickr>
                    </vx-input-group>
                  </template>
                </vx-input-group>
                <div class="flex items-center ml-2">
                  <vs-button class="px-7 py-3 text-xs text-center" color="primary" :disabled="loading.filtering" @click="refresh">
                    {{ loading.filtering ? 'Loading...' : 'Filter' }}
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">Module</vs-th>
          <vs-th class="whitespace-no-wrap">User</vs-th>
          <vs-th class="whitespace-no-wrap">Description</vs-th>
          <vs-th class="whitespace-no-wrap">Subject Type</vs-th>
          <vs-th class="whitespace-no-wrap">Properties</vs-th>
          <vs-th class="whitespace-no-wrap">Date</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td class="align-top" :data="item.module">{{ item.module }}</vs-td>
            <vs-td class="align-top" :data="item.username">{{ item.username }}</vs-td>
            <vs-td class="align-top" :data="item.description">{{ item.description }}</vs-td>
            <vs-td class="align-top" :data="item.subject_type">{{ item.subject_type }}</vs-td>
            <vs-td class="align-top" :data="item.properties">{{ item.properties }}</vs-td>
            <vs-td class="align-top" :data="item.created_at">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>
  </div>
</template>

<script>
import ActivityLog from '@/repositories/general/activity-log-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ActivityLog',
  components: {
    flatPickr,
    PagingLimit
  },
  props: {
    externalFilter: { default: null, type: Object }
  },
  data () {
    return {
      loading: {
        filtering: false,
        processing: false
      },
      filter: {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
      },
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)
      params.from_date = this.filter.fromDate
      params.to_date = this.filter.toDate

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      ActivityLog.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    onPageChange () {
      this.getData()
    },
    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    refresh () {
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>

<style scoped>

</style>
